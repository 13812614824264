<template>
  <div class="body">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
</style>

<style>
</style>